import React, { useState } from 'react'
import * as S from './style'
import Layout from 'src/layouts/BaseLayout'
import pageContext from './pageContext.json'
import { Modal } from 'src/components/Modal'
import SocioTorcedorOpenAccountRightForm from 'src/components/UI/Forms/SocioTorcedorOpenAccountRightForm'
import useDomReady from 'src/hooks/window/useDomReady'
import useDataLayer from 'src/hooks/useDataLayer/dataLayerBody'

import {
  Hero,
  AindaMaisVantagens,
  ContaDigitalGratuita,
  IndiqueAmigosTorcedores,
} from './sections/_index'
import { IDataLayerParams } from 'src/hooks/useDataLayer/typesUseDataLayer'

const AthleticoParanaense = () => {
  const domReady = useDomReady()
  const [ sendDatalayerEvent ] = useDataLayer()
  const [ openModal, setOpenModal ] = useState(false)
  const [ sectionOrderClick, setSectionOrderClick ] = useState('')
  const [ currentDataLayer, setCurrentDataLayer ] = useState<IDataLayerParams>({
    section: 'dobra_1',
    section_name: 'Torcedor do Athletico Paranaense tem ainda mais benefícios com a Conta Digital Inter',
    element_action: 'click button',
    element_name: 'Quero meu cartão',
  })

  const handleClick = (sectionOrder: string, dataLayer: IDataLayerParams) => {
    setOpenModal(!openModal)
    setSectionOrderClick(sectionOrder)
    setCurrentDataLayer(dataLayer)
    sendDatalayerEvent(dataLayer)
  }

  const socioTorcedorOpenAccountRightForm = domReady && (
    <Modal isModalOpen={openModal} setIsModalOpen={setOpenModal} locationToRender={document.body}>
      <SocioTorcedorOpenAccountRightForm closeModal={() => setOpenModal(false)} sectionOrder={sectionOrderClick} dataLayer={currentDataLayer} customOrigin='Athletico Paranaense' />
    </Modal>
  )

  return (
    <S.Wrapper>
      {socioTorcedorOpenAccountRightForm}
      <Layout pageContext={pageContext} handleHeaderOpenAccount={() => setOpenModal(true)}>
        <Hero openModal={handleClick} sectionOrder='1' />
        <AindaMaisVantagens openModal={handleClick} sectionOrder='3' />
        <ContaDigitalGratuita openModal={handleClick} sectionOrder='4' />
        <IndiqueAmigosTorcedores sectionOrder='5' />
      </Layout>
    </S.Wrapper>
  )
}

export default AthleticoParanaense
