import { white } from 'src/styles/colors'
import styled from 'styled-components'

import BackgroundMobile from '../../assets/image/background-hero-athletico-paranaense-mobile.jpg'
import BackgroundMD from '../../assets/image/background-hero-athletico-paranaense-md.jpg'
import BackgroundLG from '../../assets/image/background-hero-athletico-paranaense-lg.jpg'
import BackgroundXL from '../../assets/image/background-hero-athletico-paranaense-xl.jpg'

import { device } from 'src/styles/breakpoints'

export const Section = styled.section`
  background-image: url(${BackgroundMobile});
  background-repeat: no-repeat;
  background-size: cover;

  @media ${device.tablet} {
    background-image: url(${BackgroundMD});
  }

  @media ${device.desktopLG} {
    background-image: url(${BackgroundLG});
  }

  @media ${device.desktopXL} {
    background-image: url(${BackgroundXL});
  }

  button, a {
    height: 48px;
    @media ${device.tablet} {
      width: 178px;
    }
  }
`

export const Card = styled.div`
  background-color: ${white};
  border-radius: 8px;
  @media ${device.tablet} {
    width: 232px;
    height: 156px;
  }
`

export const AthleticoBenefits = styled.div`
.benefits {
  margin-bottom: 16px;
}
.benefits:last-child {
  margin-bottom: 0;
 }
`

export const Cards = styled.div`
.card {
  margin-right: 0;
  margin-bottom: 16px;
  @media ${device.desktopXL} {
    margin-bottom: 0;
    margin-top: 0;
  }
}

.card:last-child {
  margin-bottom: 0;
  @media ${device.desktopXL} {
    grid-column: 2;
  }
 }
`
