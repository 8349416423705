import styled from 'styled-components'
import { device } from 'src/styles/breakpoints'

import BackgroundCampoFutebol from '../../assets/image/background-campo-futebol.png'

export const Section = styled.section`
  background-image: url('https://central-imagens.bancointer.com.br/images-without-small-versions/dobra06-athletico-bg/image.webp');
  background-repeat: no-repeat;
  background-size: cover;

  @media ${device.tablet} {
    background-image: url('https://central-imagens.bancointer.com.br/images-without-small-versions/dobra06-athletico-bg/image.webp');
  }

  @media ${device.desktopLG} {
    height: 623px;
    background-image: url('https://central-imagens.bancointer.com.br/images-without-small-versions/dobra06-athletico-bg/image.webp');
  }

  @media ${device.desktopXL} {
    height: 928px;
    background-image: url('https://central-imagens.bancointer.com.br/images-without-small-versions/dobra06-athletico-bg/image.webp');
  }

  button, a {
    height: 48px;
    @media ${device.tablet} {
      width: 178px;
    }
  }
`

export const DivBackgroundCampoFutebol = styled.div`
  @media ${device.desktopLG} {
    width: 935px;
    height: 483px;
    background-image: url(${BackgroundCampoFutebol});
    background-repeat: no-repeat;
    background-size: cover;
  }

  @media ${device.desktopXL} {
    width: 1127px;
    height: 582px;
  }
`
