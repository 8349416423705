import { useStaticQuery, graphql } from 'gatsby'

const PageQuery = () => {
  const pageQuery = useStaticQuery(graphql`
    query {
      LogoAthleticoParanaenseInter: imageSharp(fluid: { originalName: { regex: "/logo-athletico-paranaense-inter/" }}) {
        fluid(maxWidth: 228, quality: 100) {
          ...GatsbyImageSharpFluid
          ...GatsbyImageSharpFluidLimitPresentationSize
        }
      }
    }
  `)

  return pageQuery
}

export default PageQuery
