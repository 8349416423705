import React from 'react'
import FlowerGraphism from '../../assets/image/flower-graphism.png'
import ImageWebp from 'src/components/ImageWebp'

import dataJson from './data/data.json'

import * as S from './styles'
import { IDataLayerParams } from 'src/hooks/useDataLayer/typesUseDataLayer'

interface IContaDigitalGratuita {
  lang: string;
}

interface IContaDigitalGratuitaProps {
  openModal: (sectionOrder: string, dataLayer: IDataLayerParams) => void;
  sectionOrder: string;
}

const ContaDigitalGratuita = ({ openModal, sectionOrder }: IContaDigitalGratuitaProps) => {
  const benefitsDigitalAccount: string[] = dataJson

  const arrayNumbers: number[] = [
    312, 336, 376, 456,
  ]

  const arrayHeight: number[] = [
    380, 409, 457, 555,
  ]

  return (
    <S.Section className='py-5'>
      <div className='container'>
        <div className='row'>
          <div className='col-12 col-md-6 col-lg-5'>
            <div>
              <ImageWebp
                pathSrc='https://central-imagens.bancointer.com.br/images-without-small-versions/dobra4-1440-athletico/image.webp'
                altDescription='Ilustração de uma mulher aproveitando os beneficios da conta digital gratuita Inter'
                arrayNumbers={arrayNumbers}
                arrayNumbersHeight={arrayHeight}
              />
            </div>
          </div>
          <div className='col-12 col-md-6 col-lg-7 col-xl-6 offset-xl-1 pl-md-0 pr-xl-0'>
            <h2 className='fs-32 lh-40 fs-md-40 lh-md-50 fw-400 text-grayscale--500 mb-4'>Conta digital gratuita, cartão de crédito sem anuidade e com muitos pontos!</h2>
            <p className='fs-18 lh-22 fw-400 text-grayscale--500 mb-0'>Um Super App que conecta soluções que a sua vida precisa em um só lugar. Lá você vai encontrar:</p>
            <div className='d-none d-lg-block'>
              <S.DigitalAccountBenefits className='my-4'>
                {benefitsDigitalAccount.map((text: string) => (
                  <div key={text} className='benefits d-flex align-items-center'>
                    <div className='mr-2'>
                      <img src={FlowerGraphism} alt='Icone Flower Graphism' />
                    </div>
                    <div>
                      <h3 className='fs-18 lh-22 fw-700 text-grayscale--500 mb-0' dangerouslySetInnerHTML={{ __html: text }} />
                    </div>
                  </div>
              ))}
              </S.DigitalAccountBenefits>
            </div>
            <div className='d-none d-lg-block'>
              <button
                onClick={() => openModal(sectionOrder, {
                  section: 'dobra_4',
                  section_name: 'Conta digital gratuita, cartão de crédito sem anuidade e muito cashback!',
                  element_action: 'click button',
                  element_name: 'Abrir conta',
                })}
                title='Abrir conta'
                className='btn btn-orange--extra btn--lg rounded-2 text-none text-md-center'
              >
                Abrir conta
              </button>
            </div>
          </div>
          <div className='col-12 d-lg-none'>
            <S.DigitalAccountBenefits className='my-4'>
              {benefitsDigitalAccount.map((text: string) => (
                <div key={text} className='benefits d-flex align-items-center'>
                  <div className='mr-2'>
                    <img src={FlowerGraphism} alt='Icone Flower Graphism' />
                  </div>
                  <div>
                    <p className='fs-18 lh-22 fw-700 text-grayscale--500 mb-0' dangerouslySetInnerHTML={{ __html: text }} />
                  </div>
                </div>
              ))}
            </S.DigitalAccountBenefits>
          </div>
          <div className='col-12 d-lg-none'>
            <div>
              <button
                onClick={() => openModal(sectionOrder, {
                  section: 'dobra_4',
                  section_name: 'Conta digital gratuita, cartão de crédito sem anuidade e muito cashback!',
                  element_action: 'click button',
                  element_name: 'Abrir conta',
                })}
                title='Abrir conta'
                className='btn btn-orange--extra btn--lg rounded-2 text-none text-md-center'
              >
                Abrir conta
              </button>
            </div>
          </div>
        </div>
      </div>
    </S.Section>
  )
}

export default ContaDigitalGratuita
