import React from 'react'
import ImageWebp from 'src/components/ImageWebp'

import { Section } from './styles'
import { IDataLayerParams } from 'src/hooks/useDataLayer/typesUseDataLayer'

interface IHeroProps {
  openModal: (sectionOrder: string, dataLayer: IDataLayerParams) => void;
  sectionOrder: string;
}

const AindaMaisVantagens = ({ openModal, sectionOrder }: IHeroProps) => {
  const arrayNumbers: number[] = [
    0, 0, 376, 512,
  ]

  const arrayHeight: number[] = [
    0, 0, 349, 476,
  ]

  return (
    <Section className='py-5 bg-grayscale--500'>
      <div className='container'>
        <div className='row align-items-lg-center'>
          <div className='col-12 col-lg-7 col-xl-6'>
            <h2 className='fs-32 lh-40 fs-md-40 lh-md-50 fw-400 text-white mb-4'>Ainda mais vantagens <span className='d-xl-block'>para o Sócio Furacão em</span> dias de jogos!</h2>
            <p className='fs-18 lh-22 text-white pb-3'>O Cartão Inter é um combo de benefícios em um lugar só: cartão de crédito sem anuidade e com acúmulo de pontos, cartão de débito, cartão internacional e funciona como um cartão exclusivo para Sócio Furacão que dá acesso ao estádio.</p>
            <div className='mt-4'>
              <button
                onClick={() => openModal(sectionOrder, {
                  section: 'dobra_3',
                  section_name: 'Ainda mais vantagens para o Sócio Furacão em dias de jogos!',
                  element_action: 'click button',
                  element_name: 'Abrir conta',
                })}
                title='Abrir conta'
                className='btn btn-orange--extra btn--lg rounded-2 text-none text-md-center'
              >
                Abrir conta
              </button>
            </div>
          </div>
          <div className='d-none d-lg-flex justify-content-lg-end col-lg-5 col-xl-6'>
            <ImageWebp
              pathSrc='https://central-imagens.bancointer.com.br/images-without-small-versions/cartao-athletico-paranaense/image.webp'
              altDescription='Cartão Athletico Paranaense'
              arrayNumbers={arrayNumbers}
              arrayNumbersHeight={arrayHeight}
            />
          </div>
        </div>
      </div>
    </Section>
  )
}

export default AindaMaisVantagens
