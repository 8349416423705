import { device } from 'src/styles/breakpoints'
import styled from 'styled-components'

export const Section = styled.section`
  button, a {
    height: 48px;
    @media ${device.tablet} {
      width: 178px;
    }
  }
`

export const DigitalAccountBenefits = styled.div`
.benefits {
  margin-bottom: 16px;
}
.benefits:last-child {
  margin-bottom: 0;
 }

 @media ${device.tablet} {
    display: grid;
    grid-template-columns: repeat(2,1fr);
 }

 @media ${device.desktopXL} {

 }
`
